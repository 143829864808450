<template>
  <div>
    <p>
      To document my musical experimentations, I have created a blog using a
      custom instance of wordpress :
      <a href="http://blog.tomansion.fr/">HERE</a>
    </p>

    <p>
      I have wrote a few articles about the designs and 3D models that I have
      made :
    </p>
    <img
      src="@/assets/images/blog/all_designs.png"
      class="center"
      style="width: 59%"
    />
    <p>about my new 1.000.000$ musical idea :</p>

    <img
      src="@/assets/images/blog/percusition.png"
      class="center"
      style="width: 89%"
    />
    <p>and about the other things that I have made :</p>
    <div style="display: flex">
      <img
        src="@/assets/images/blog/yolo.png"
        class="center"
        style="width: 39%"
      />
      <img
        src="@/assets/images/blog/canope.png"
        class="center"
        style="width: 49%"
      />
    </div>
    <p>So check it out !</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>