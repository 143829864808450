<template>
  <section id="perso">
    <h1>I am</h1>
    <p>
      I am a computer science engineer student at the
      <a href="https://www.polytech.universite-paris-saclay.fr/"
        >Polytech PARIS-SACLAY university</a
      >
      in an apprenticeship at the
      <a href="https://www.irt-systemx.fr/en/">IRT SystemX</a>
      since 2018.
    </p>
    <p>
      I enjoy working on <u>Vuejs</u>, <u>Python</u> or <u>NodeJs</u> computer
      science projects, you can find below a short overview of several project
      I have worked on.
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
#perso {
  padding: 20px;
}
h1 {
  text-align: left;
}
#title {
  padding: 30px;
}

#logos {
  display: flex;
}
.logo {
  height: 100px;
}
</style>