<template>
  <div>
    <h3>Context</h3>
    <p>
      During my last year of high school, I had to lead a small group of
      beginners developers and make a Python game.
    </p>

    <h3>The game features :</h3>
    <h4>Cool visuals :</h4>
    <img
      src="@/assets/images/tmcp/menu.png"
      class="center screencap"
      style="width: 90%"
    />
    <img
      src="@/assets/images/tmcp/game1.png"
      class="center screencap"
      style="width: 90%"
    />
    <img
      src="@/assets/images/tmcp/gam2.png"
      class="center screencap"
      style="width: 90%"
    />
    <h4>Prodecuraly genarated dugeons : </h4>
    <img
      src="@/assets/images/tmcp/levels.png"
      class="center"
      style="width: 90%"
    />
    <img
      src="@/assets/images/tmcp/levels2.png"
      class="center"
      style="width: 40%"
    />
    <h4>hand made tileset and automatic sprite placements</h4>
    <img
      src="@/assets/images/tmcp/tileset.png"
      class="center"
      style="width: 40%"
    />
    <h4>A simple IA</h4>
    <h4>A field of view, ray tracing algorithm</h4>
    <h4>Object oriented programming</h4>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>