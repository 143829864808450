<template>
  <div id="projects">
    <!-- upper arrow -->
    <button @click="top()" id="topBtn" title="Go to top">&#8593;</button>

    <!-- projects -->
    <div class="row">
      <div class="col">
        <h2><b>IRT SystemX</b></h2>
        <div class="tabs">
          <div class="tab">
            <input type="checkbox" id="DEBIAI" />
            <label class="tab-label" for="DEBIAI"
              ><span
                ><b>DEBIAI :</b> Creation of a web application aiming to
                visualize a AI data and model results
              </span></label
            >
            <div class="tab-content">
              <DEBIAI />
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="CDF" />
            <label class="tab-label" for="CDF"
              ><span
                ><b>CDF :</b> Improvement and maintenance of a web application
                allowing to visualize simulations results in the form of 3D
                models
              </span></label
            >
            <div class="tab-content">
              <CDF />
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="MSM" />
            <label class="tab-label" for="MSM"
              ><span
                ><b>MSM :</b> Upgrade and creation of multiple Java, Python and
                Node services
              </span></label
            >
            <div class="tab-content">
              <MSM />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2><b>School projects</b></h2>
        <div class="tabs">
          <div class="tab">
            <input type="checkbox" id="Repowee" />
            <label class="tab-label" for="Repowee"
              ><span
                ><b>Repowee :</b> The automatic free for student web application
                host</span
              ></label
            >
            <div class="tab-content">
              <Repowee />
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="compiler" />
            <label class="tab-label" for="compiler"
              ><span
                ><b>A compiler :</b> A Java compiler that compile a custom made
                langage</span
              ></label
            >
            <div class="tab-content">
              <Compiler />
            </div>
          </div>
          <div class="tab">
            <input type="checkbox" id="ToMaCinpy" />
            <label class="tab-label" for="ToMaCinpy"
              ><span
                ><b>TohMaCynPy Quest :</b> Creation of a python 2D game</span
              ></label
            >
            <div class="tab-content">
              <ToMaCinPy />
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <h2><b>Personal projects</b></h2>
        <div class="tabs">
          <div class="tab">
            <input type="checkbox" id="Survaillant" />
            <label class="tab-label" for="Survaillant"
              ><span
                ><b>Survaillant :</b> Desing and creation of a web multiplayer
                game
              </span></label
            >
            <div class="tab-content">
              <Survaillant />
            </div>
          </div>
        </div>
        <div class="tabs">
          <div class="tab">
            <input type="checkbox" id="Melocussion" />
            <label class="tab-label" for="Melocussion"
              ><span
                ><b>Melocussion :</b> A hand made electronic music instrument
              </span></label
            >
            <div class="tab-content">
              <Melocussion />
            </div>
          </div>
        </div>
        <div class="tabs">
          <div class="tab">
            <input type="checkbox" id="Blog" />
            <label class="tab-label" for="Blog">
              <span>
                <b>Blog :</b> A Wordpress blog documenting my experiences
              </span></label
            >
            <div class="tab-content">
              <Blog />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CDF from "./projects/CDF";
import Compiler from "./projects/Compiler";
import DEBIAI from "./projects/DEBIAI";
// import GeneAlgo from "./projects/GeneAlgo";
import MSM from "./projects/MSM";
import Repowee from "./projects/Repowee";
// import SizeGame from "./projects/SizeGame";
import ToMaCinPy from "./projects/ToMaCinPy";
import Survaillant from "./projects/Survaillant";
import Melocussion from "./projects/Melocussion.vue";
import Blog from "./projects/Blog.vue";

export default {
  components: {
    CDF,
    Compiler,
    DEBIAI,
    // GeneAlgo,
    MSM,
    Repowee,
    // SizeGame,
    ToMaCinPy,
    Survaillant,
    Melocussion,
    Blog,
  },
  mounted() {
    window.onscroll = () => {
      this.scroll();
    };
    this.topBtn = document.getElementById("topBtn");
  },
  methods: {
    scroll() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.topBtn.style.display = "block";
      } else {
        this.topBtn.style.display = "none";
      }
    },

    // When the user clicks on the button, scroll to the top of the document
    top() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style lang="scss">
#projects {
  padding: 1em;
}
$midnight: #2c3e50;
$clouds: #ecf0f1;

h1 {
  margin: 0;
  line-height: 2;
  text-align: center;
}
h2 {
  margin: 0 0 0.5em;
  font-weight: normal;
}
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
// Layout
.row {
  display: flex;
  .col {
    flex: 1;
  }
  .col + .col {
    margin-left: 1em;
  }
  margin-bottom: 1em;
}
/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background: $midnight;
    cursor: pointer;
    b {
      padding-right: 1em;
    }
    /* Icon */
    &:hover {
      background: darken($midnight, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $midnight;
    background: white;
    // transition: all 0.5s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $midnight;
    cursor: pointer;
    &:hover {
      background: darken($midnight, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($midnight, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    padding: 1em;
    max-height: 10000vh;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.screencap {
  width: 90%;
  margin-bottom: 2em;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

#topBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $midnight;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 100vh;
  font-size: 18px;
}

#topBtn:hover {
  background: darken($midnight, 10%);
}
</style>