<template>
  <div>
    <h3>Context</h3>
    <p>
      As part of our training as an IT engineer, we were asked to carry out a
      WEB project, in a group. Our goal was to acquire knowledge around a
      project with a free subject. In order to work together on this project, we
      formed a group of three.
    </p>
    <p>
      We all come from a DUT in which we have already acquired a certain level
      in WEB development. It was noted, during the practical sessions of WEB
      development that there is, to our knowledge, no system used to host a
      website or other service. Having a website or a hosted service makes it
      possible in particular to present it to members of your group or to your
      public works manager. There is no server on which to deploy your
      application, so in addition to having a certain level of system
      administration knowledge, you need to pay your own remote server.
    </p>
    <p>
      In order to allow students to deploy their applications without being an
      expert in administration and for free, and thus to allow professors to
      have access to the applications and possibly to evaluate them, we are
      going to create a WEB application: REPOWEE
    </p>
    <h3>Use case</h3>
    <img
      src="@/assets/images/repowee/useCase.png"
      class="center"
      style="width: 80%"
    />
    <h3>Archtecture</h3>
    <img
      src="@/assets/images/repowee/archi.png"
      class="center"
      style="width: 100%"
    />
    <h3>The web application</h3>
    <img
      src="@/assets/images/repowee/homePage.png"
      class="center"
      style="width: 50%"
    />
    <img
      src="@/assets/images/repowee/homePage2.png"
      class="center"
      style="width: 100%"
    />
    <h3>Conclusion</h3>
    <p>
      This project was a lot of fun for the three of us whether it was through
      the use of unfamiliar language or the originality of the project. We had
      disagreements on points but each time we dealed with it. Each one of us
      made progress in certain areas, be it social or IT. We have also benefited
      from the knowledge of others.
    </p>
    <p>
      The ambition of this project has motivated us to produce a pleasant and
      functional website for the user. There are still things to be produced to
      date but we are confident about the future and the finality of the project
      which we hope will be a great success.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>