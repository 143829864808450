<template>
  <div>
    <h3>Context</h3>
    <p>
      During our compilation courses, we had to make a compiler. We made ours
      with Java.
    </p>
    <h3>Compiler work flow</h3>
    <img
      src="@/assets/images/compilo/flow.png"
      class="center"
      style="width: 100%"
    />
    <p>
      This project was ambitious, yet very learnfull.<br />
      It helped me with a personal project where I had to read the
      input from a keyboard and translate it into code instructions.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>