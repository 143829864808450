<template>
  <div>
    <h3>Context</h3>
    <p>
      In order to optimize its bus, tram and metro networks, RATP called on IRT
      researchers. The MSM team is building a platform to visualize existing
      lines and anticipate the impact that a disruption would have on a line.
      Researchers need a tool that can interact with their simulation algorithms
      and display the results in real time on a web interface.
    </p>
    <p>
      I was invited to work on the MSM project until his end in June 2020.
      For this project, I had to take over and improve multiple services of
      the “BackEnd” part of the platform so that it interacts with the COSMO
      simulation tool.
    </p>
    <p>
      I had to upgrade a Java and a Node service and to create a new Python web socket realtime data transmiter.
    </p>
    <h3>The structure of the services I worked on</h3>
    <img src="@/assets/images/MSM/globPlan.png" class="center" style="width:50%"/>
    <h3>The Java Cosmo Pertubation Processor service detailed workflow </h3>
    <img src="@/assets/images/MSM/pertProc.png" class="center" style="width:70%"/>
    <h3>The Python Cosmo Results comparator service detailed workflow </h3>
    <img src="@/assets/images/MSM/resultComp.png" class="center" style="width:60%"/>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>