<template>
  <div>
    <p>
      Survaillant is a web multiplayer game that I have created to practices
      graphical user experience. This project allows me to learn new tools that
      I wouldn't have learned in school or at work.
    </p>
    <p>
      You can find a demonstration of the game
      <a href="https://survaillant.tomansion.fr">HERE</a>
    </p>

    <h4>The main menu :</h4>
    <img
      src="@/assets/images/survaillant/main_menu.png"
      class="center screencap"
      style="width: 90%"
    />

    <h4>Map selection :</h4>
    <img
      src="@/assets/images/survaillant/map_selection.png"
      class="center screencap"
      style="width: 90%"
    />
    <h4>Gameplay :</h4>
    <img
      src="@/assets/images/survaillant/gameplay.png"
      class="center screencap"
      style="width: 90%"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>