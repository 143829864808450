<template>
  <div>
    <p>
      I alwas wanted to play some music, and building my own music instruments
      was for me a very interesting way of beginnig this journey.
    </p>
    <p>
      I have made a blog that explains why and how I have builded the
      Melocussion
      <a href="http://blog.tomansion.fr/">HERE</a>
    </p>

    <h4>Me and the Melocussion</h4>
    <p>During a live à</p>
    <img
      src="@/assets/images/melocussion/me.jpg"
      class="center"
      style="width: 59%"
    />

    <h4>The melocussion</h4>
    <div style="display: flex">
      <img
        src="@/assets/images/melocussion/1.jpg"
        class="center"
        style="width: 30%"
      />
      <img
        src="@/assets/images/melocussion/2.jpg"
        class="center"
        style="width: 30%"
      />
      <img
        src="@/assets/images/melocussion/3.jpg"
        class="center"
        style="width: 30%"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>