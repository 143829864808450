<template>
  <div>
    <h3>Context</h3>
    <p>
      In order to find solutions based on machine learning algorithms, the
      EngageAI team must train and compare many models in order to select the
      best of them. To evaluate the performance of a model, indicators such as
      accuracy and loss, generated during its evaluation, are used.
    </p>

    <p>
      To be able to compare the results of a model with the environmental
      contexts of its evaluation allows us to better understand the impact of
      these contexts on the results, something not featured in the current
      tools. <br />
      A comparison between the contexts and the result of an evaluation of a
      model allows between others, to an AI team, to identify the environmental
      limits of the model and thus define areas for improvement for future
      learning.
    </p>
    <p>
      So, the IRT SystemX AI team gave me the mission to create a web
      application to compare the results of a model with the context in which it
      was evaluated, thus giving a better idea of its real performance.
    </p>
    <p>The work is still in progress</p>

    <h3>Application architecture</h3>
    <p>
      The DEBIAI application is made of a Python backend, a Python module and a vueJs
      dashboard :
    </p>
    <img src="@/assets/images/DEBIAI/DEBIAI_archiSimple.png" class="center" style="width:30%"/>
    <h3>Application dashboard</h3>
    <img src="@/assets/images/DEBIAI/1.png" class="center screencap" />
    <img src="@/assets/images/DEBIAI/2.png" class="center screencap" />
    <img src="@/assets/images/DEBIAI/3.png" class="center screencap" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

</style>