<template>
  <div>
    <h3>Context</h3>
    <p>
      The CDF project (Design of Manufacturing Guidelines) concern the process
      of LMD additive manufacturing (Laser Metal Deposition). This process
      allows, in a large 3D printer, to create metal parts using metal powder
      projection and laser beam fusion.
    </p>

    <img src="@/assets/images/CDF/lmd.png" class="center" style="width: 60%" />
    <p>
      However, this process lacks simulation tools that would allow to avoid
      printing errors and improve delivery industrial process. It is on this
      aspect that the IRT SystemX has participated.
    </p>

    <p>
      The objective of IRT SystemX Software and Devops team I work in was to
      create a platform for launching simulation and visualization of results.
      This platform should make it possible to execute and then visualize the
      results of the various simulation algorithms created by the IRT's research
      teams. Members of the S&D team invited me to work with them on data
      visualization, data generated from simulations launched from the platform.
    </p>

    <p>
      My role in this project has evolved over the 10 months I've worked on. I
      first created proof of concept for visualization of 3D model for the web,
      I then had to implement different visualization interfaces in the
      application and finally, with the departure of a colleague, I became the
      main contributor to the web application.
    </p>

    <h3>Application dashboard</h3>

    <h4>Kin analysis :</h4>
    <img src="@/assets/images/CDF/kin.png" class="center screencap" />
    <img src="@/assets/images/CDF/kinA.png" class="center" style="width: 80%" />
    <h4>Hoverhang analysis :</h4>
    <img src="@/assets/images/CDF/paf.png" class="center screencap" />
    <h4>A simulation list :</h4>
    <img
      src="@/assets/images/CDF/projects.png"
      class="center"
      style="width: 60%"
    />

    <h3>Conclusion</h3>
    <p>
      During the entire duration of the project, I gained a lot of skills in the
      field of web development. I learned to use many tools for displaying data
      and creating interfaces (VueJs, Plotly.js, noUiSlider, ...).
    </p>
    <p>
      What I have learned is not limited to development. Working on a project
      that has multiple members brings certain responsibilities, I learned to
      version, secure and deploy my work without compromising that of others.
      Demonstrating my work and listening to the remarks and proposals made to
      me during the CDF plenary sessions was very enriching. Jotting down and
      exchanging ideas with whoever needs a visualization tool is one of my
      favorite moments in creating an interface.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>