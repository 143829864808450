<template>
  <div id="FrontPage">
    <Header/>
    <Personal/>
    <Projects/>
  </div>
</template>

<script>
import Header from "./Header";
import Personal from "./Personal";
import Projects from "./Projects";

export default {
  components: {
    Header,
    Personal,
    Projects,
  },
  methods: {
    imageError(e1, e2, e3) {
      console.log(e1);
      console.log(e2);
      console.log(e3);
    },
  },
};
</script>

<style>
#FrontPage{
  height:  100%;
}

</style>