<template>
  <header>
    <img slot="image" id="profilePic" src="@/assets/images/frontPage/tom.png" />
    <div id="title">
      <h1>Tom Mansion</h1>
      <h2>Portfolio</h2>
    </div>
    <div style="flex: 1"></div>
    <div id="logos">
      <a href="https://www.irt-systemx.fr/en/">
        <img
          slot="image"
          class="logo"
          src="@/assets/images/frontPage/system-x-logo.jpeg"
        />
      </a>
      <a href="https://www.polytech.universite-paris-saclay.fr/">
        <img
          slot="image"
          class="logo"
          src="@/assets/images/frontPage/polytechParisSaclay.png"
        />
      </a>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
header {
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  padding: 20px;
}
#profilePic {
  border-radius: 100%;
  height: 150px;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}
#title {
  padding: 30px;
}

#logos {
  display: flex;
}
.logo {
  height: 100px;
}
</style>